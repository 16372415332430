@use "~@fremtind/jkl-core/jkl";
@use "~@fremtind/jkl-core/variables/_colors.scss";
@import "~@fremtind/jkl-core/variables/all";
@import "~@fremtind/jkl-core/functions";
@import "~@fremtind/jkl-core/variables/_spacing.scss";

$medium: 900px;

.project-card {
    width: 100%;
    display: flex;
    flex-direction: row;

    &--spacing {
        justify-content: flex-end;
    }

    @media screen and (max-width: $medium) {
        display: block;
        margin-top: $layout-spacing--small;
    }

    &__title-container {
        padding: $component-spacing--large;
        background-color: $granitt;
        margin: 0;
        color: $snohvit;
    }

    &__image {
        justify-self: center;
        max-width: 100%;
        max-height: 100%;
    }

    &__text {
        background-color: $granitt;
        padding: 1rem;
        color: $snohvit;
        text-align: center;

        &--year {
            @media screen and (max-width: $medium) {
                display: none;
            }
        }
    }
    &__button {
        cursor: pointer;
        color: $granitt;
    }

    &__actions-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
