@use "~@fremtind/jkl-core/jkl";
@use "~@fremtind/jkl-core/variables/_colors.scss";
@import "~@fremtind/jkl-core/mixins/_all.scss";
@import "~@fremtind/jkl-core/mixins/_helpers.scss";
@import "~@fremtind/jkl-core/variables/all";
@import "~@fremtind/jkl-core/functions";

.wrapper {
    &__background {
        background-image: url("./mountain.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100vh;
        min-height: auto;

        &--dark-mode {
            color: $snohvit;
        }

        &--more-content {
            background-image: url("./mountain-more.png");
            height: 200vh;
        }
        &--more-content-compact {
            background-image: url("./mountain-more.png");
            min-height: 200vh;
            height: max-content;
        }
        &--more-content-compact-auto {
            background-image: url("./mountain-more.png");
            min-height: 100vh;
            height: auto;
        }
    }

    &__content {
        padding: 3rem;
    }
}
