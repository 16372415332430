@use "~@fremtind/jkl-core/jkl";
@use "~@fremtind/jkl-core/variables/_colors.scss";
@import "~@fremtind/jkl-core/variables/all";
@import "~@fremtind/jkl-core/functions";
@import "~@fremtind/jkl-core/mixins/_all.scss";
@import "~@fremtind/jkl-core/mixins/_helpers.scss";

$medium: 900px;

.header {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    background-color: transparent;
    align-items: center;
    height: 5rem;
    justify-content: space-between;

    &__title {
        width: 50%;
        color: $snohvit;
        @media screen and (max-width: $medium) {
            @include jkl.text-style("body/small-screen");
        }
    }

    &__links {
        width: 25%;
        display: flex;
        flex-direction: row;

        @media screen and (max-width: $medium) {
            width: auto;
        }
    }

    &__link {
        margin-left: $component-spacing--large;
        cursor: pointer;
        color: $snohvit;

        @media screen and (max-width: $medium) {
            font-size: 12px;
        }
    }
}
